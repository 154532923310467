"use client";

import React from "react";
import { BackgroundBeams } from "./components/bg-beam";
import { FlipWords } from "./components/flip-words";
import logo from "./logo.png";

export default function Home() {
  const words = [
    "Bean",
    "Tasting",
    "Profile",
    "Processor",
    "Altitude",
    "Varietal",
  ];

  return (
    <div className="min-h-screen w-full bg-neutral-950 relative flex flex-col items-center justify-center antialiased">
      <div className="max-w-2xl mx-auto p-4">
        <div className="items-center justify-center flex">
          <img src={logo} className="w-52 animate-spin-slow" alt="logo" />
        </div>
        <div className="text-4xl mx-auto font-normal text-zinc-300 dark:text-neutral-400 mb-8">
          Discover Coffee
          <FlipWords words={words} /> <br />
          with <span className="underline">Pranala Coffee API</span>
        </div>
        <p className="text-neutral-400 max-w-lg mx-auto my-2 text-sm relative z-10">
          Discover the world of coffee like never before with our comprehensive
          Coffee API. Whether you’re a coffee aficionado, a developer building
          the next great coffee app, or a business looking to integrate detailed
          coffee data, our API provides you with the tools you need.{" "}
          <span className="font-medium underline">
            <a href="https://pranala.coffee/documentation/v1.0.0">
              Here's our documentation (v1.0.0)
            </a>
          </span>
        </p>
        <a
          href="mailto:pranala.coffee@gmail.com"
          className="mt-4 bg-slate-800 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px text-xs font-semibold leading-6  text-white inline-block"
        >
          <span className="absolute inset-0 overflow-hidden rounded-full">
            <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </span>
          <div className="relative flex space-x-2 items-center z-10 rounded-full bg-zinc-950 py-0.5 px-4 ring-1 ring-white/10 ">
            <span>Contact Us</span>
            <svg
              fill="none"
              height="16"
              viewBox="0 0 24 24"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.75 8.75L14.25 12L10.75 15.25"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-emerald-400/0 via-emerald-400/90 to-emerald-400/0 transition-opacity duration-500 group-hover:opacity-40" />
        </a>
      </div>
      <BackgroundBeams />
    </div>
  );
}
